import React from 'react';
import './LoadingButton.css';
class LoadingButton extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {

        if (this.props.isLoading && this.props.small) {
            return (<div className="loadingButtonContainer">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
            )
        }
        if (this.props.isLoading) {
            return <div className="loadingButtonContainer">
                <div class="lds-dual-ring"></div>
            </div>
        }
        return (
            <div className="loadingButtonContainer"
                onClick={this.props.onClick}>{this.props.text}</div>
        )
    }
}
export default LoadingButton;