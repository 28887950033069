import React from 'react';
import { toast } from 'react-toastify';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import UnitSelector from '../../UnitSelector/UnitSelector';
import { withRouter } from 'react-router-dom';
import LoadingButton from '../../Buttons/LoadingButton';
class BillOfMaterials extends React.Component {
    constructor(props) {
        super(props);
        this.saveUnitsPerCase = this.saveUnitsPerCase.bind(this);
        this.getRecipeFirstTime = this.getRecipeFirstTime.bind(this);
        this.getBOMCalculationsFirstTime = this.getBOMCalculationsFirstTime.bind(this);
        this.state = {
            showTable: true,
            currentUnits: 'kg',
            displayedUnits: 'metric_g_mL',
            magnitudePerUnit: '',
            unitsPerCase: '',
            recipe: '',
            loaded: false,
        }
    }
    componentDidMount = () => {
        // if (this.props.recipe) {
        //     this.setState({
        //         magnitudePerUnit: this.props.recipe.m_total_weight_per_unit_kg,
        //         unitsPerCase: this.props.recipe.units_per_case,
        //     })
        // }
        this.getRecipeFirstTime();
    }
    // componentDidUpdate = (prevProps) => {
    //     if (prevProps.magnitudePerUnit !== this.props.magnitudePerUnit || prevProps.unitsPerCase !== this.props.unitsPerCase) {
    //         this.setState({
    //             magnitudePerUnit: this.props.recipe.m_total_weight_per_unit_kg,
    //             unitsPerCase: this.props.recipe.units_per_case,
    //         })
    //     }
    // }
    getRecipeFirstTime() {

        let id;
        if (this.props.match) {

            id = this.props.match.params.id
        }
        else if (this.props.recipe) {
            id = this.props.recipe.id
        }
        Request.authRequest(Routes.recipes.edit(id),
            'GET').then((response) => {

                if (response.status === 200) {
                    response.json().then(body => {
                        let total_weight = body.recipe.m_total_weight_per_unit_kg;
                        let units_per_case = body.recipe.units_per_case;
                        if (body.recipe.units_per_case_bom) {
                            units_per_case = body.recipe.units_per_case_bom
                        }
                        if (body.recipe.m_total_weight_per_unit_kg_bom) {
                            total_weight = body.recipe.m_total_weight_per_unit_kg_bom
                        }
                        this.setState({
                            recipe: body.recipe, unitsPerCase: units_per_case,
                            magnitudePerUnit: total_weight,
                        }, () => this.getBOMCalculationsFirstTime(body.recipe))
                    })
                }
                else {
                    response.json().then((error) => {
                        this.setState({ loaded: true })
                        toast.error(error.message);
                    });
                }
            }).catch((error) => {
                toast.error(error.message);
            }
            )
    }
    getBOMCalculationsFirstTime(input_recipe) {

        let recipe = {
            immutable: input_recipe.immutable,
            requires_signatures: input_recipe.requires_signatures,
            ignore_density: false
        }
        recipe.units_per_case_bom = this.state.unitsPerCase
        recipe.weight_per_unit_bom = this.state.magnitudePerUnit + ' ' + this.state.currentUnits
        if (recipe.ignore_density) {
            recipe.ignore_density = input_recipe.ignore_density;
        }
        Request.authRequest(Routes.recipes.billOfMaterialsCalculator(input_recipe.id),
            'PUT', JSON.stringify(
                recipe
            ), 'application/json'
        ).then((response) => {
            if (response.status === 200) {
                response.json().then(body => {
                    this.setState({ recipe: body, loaded: true });
                });
            } else {
                response.json().then((error) => {
                    console.log(error);
                    toast.error(error.message);
                    if (error.errors) {
                        toast.error(error.errors[0])
                    }
                });
            }
        }).catch((error) => {
            toast.error(error.message);
        }
        )
    }
    onGetRecipe() {
        let id;
        if (this.props.match) {

            id = this.props.match.params.id
        }
        else if (this.props.recipe) {
            id = this.props.recipe.id
        }
        Request.authRequest(Routes.recipes.edit(id),
            'GET').then((response) => {

                if (response.status === 200) {
                    response.json().then(body => {
                        let total_weight = body.recipe.m_total_weight_per_unit_kg;
                        let units_per_case = body.recipe.units_per_case;
                        if (body.recipe.units_per_case_bom) {
                            units_per_case = body.recipe.units_per_case_bom
                        }
                        if (body.m_total_weight_per_unit_kg_bom) {
                            total_weight = body.m_total_weight_per_unit_kg_bom
                        }
                        this.setState({
                            recipe: body.recipe, loaded: true, unitsPerCase: units_per_case,
                            magnitudePerUnit: total_weight,
                        })
                    })
                }
                else {
                    response.json().then((error) => {
                        this.setState({ loaded: true })
                        toast.error(error.message);
                    });
                }
            }).catch((error) => {
                toast.error(error.message);
            }
            )
    }
    changeMagnitudePerUnit = (e) => {
        this.setState({ magnitudePerUnit: e.target.value })
    }
    changeUnits = (units) => {
        this.setState({ currentUnits: units });
    }
    changeDisplayedUnits = (e) => {
        this.setState({ displayedUnits: e.target.value });
    }
    changeUnitsPerCase = (e) => {
        this.setState({ unitsPerCase: e.target.value })
    }
    saveUnitsPerCase = () => {


        let recipe = {
            immutable: this.props.recipe.immutable,
            requires_signatures: this.props.recipe.requires_signatures,
            ignore_density: false
        }
        recipe.units_per_case_bom = this.state.unitsPerCase
        recipe.weight_per_unit_bom = this.state.magnitudePerUnit + ' ' + this.state.currentUnits
        if (recipe.ignore_density) {
            recipe.ignore_density = recipe.ignore_density;
        }
        Request.authRequest(Routes.recipes.billOfMaterialsCalculator(this.props.recipe.id),
            'PUT', JSON.stringify(
                recipe
            ), 'application/json'
        ).then((response) => {
            if (response.status === 200) {
                response.json().then(body => {
                    this.setState({ recipe: body });
                });
            } else {
                response.json().then((error) => {
                    console.log(error);
                    toast.error(error.message);
                    if (error.errors) {
                        toast.error(error.errors[0])
                    }
                });
            }
        }).catch((error) => {
            toast.error(error.message);
        }
        )
    }
    render() {
        let recipe = this.state.recipe;
        if (!recipe) {
            return null
        }
        if (!this.state.loaded) {
            return <LoadingButton />
        }
        let ingredients = recipe.recipe_ingredients;
        if (ingredients.length == 0) {
            return <div>No ingredients for this recipe.</div>
        }
        let units_per_case = recipe.units_per_case;
        if (recipe.units_per_case_bom) {
            units_per_case = recipe.units_per_case_bom;
        }
        let ignore_density = this.props.ignore_density;
        let ingredient_rows_unit = ingredients.map((ingredient, i) => {
            return (
                <tr className="BOM-row">
                    <td className="BOM-cell">{ingredient.ingredient.name}</td>
                    <td className="BOM-cell">{ingredient.f_weight_per_unit_kg}</td>
                    <td className="BOM-cell">{ingredient.f_weight_per_unit_lb}</td>
                    <td className="BOM-cell">{ingredient.f_vol_per_unit_L}</td>
                    <td className="BOM-cell">{ingredient.f_vol_per_unit_gal}</td>
                </tr>
            )
        })
        let ingredient_rows_case = ingredients.map((ingredient, i) => {
            return (
                <tr className="BOM-row">
                    <td className="BOM-cell">{ingredient.ingredient.name}</td>
                    <td className="BOM-cell">{ingredient.f_weight_per_case_kg}</td>
                    <td className="BOM-cell">{ingredient.f_weight_per_case_lb}</td>
                    <td className="BOM-cell">{ingredient.f_total_vol_per_case_L}</td>
                    <td className="BOM-cell">{ingredient.f_total_vol_per_case_gal}</td>
                </tr>
            )
        });
        return (
            <div className="billOfMaterials-container">
                <div className="bom-units">
                    <div>
                        Units:
                        <select className="unitSelector" onChange={this.changeDisplayedUnits} value={this.state.displayedUnit}>
                            <option value="metric_g_mL">Metric (g, mL)</option>
                            <option value="metric_kg_L">Metric (kg, L)</option>
                            <option value="imperial_lb_gal">Imperial (lb, kg)</option>
                        </select>
                    </div>

                    <div className="recipes-unitChanger-container">
                        <div onClick={this.saveUnitsPerCase} className="recipes-unitChanger-button">Calculate</div>
                        <div className="recipes-unitChanger-individual">
                            <input value={this.state.unitsPerCase} onChange={this.changeUnitsPerCase}
                                className="recipes-unitChangerInput" /> <div>units per case</div></div>

                        <div className="recipes-unitChanger-individual">
                            <input value={this.state.magnitudePerUnit} onChange={this.changeMagnitudePerUnit} type="number"
                                className="recipes-unitChangerInput" />
                            <UnitSelector changeUnits={this.changeUnits} unit={this.state.currentUnits} ignore_density={ignore_density} />
                            <div>per unit</div></div>


                    </div>
                </div>
                <div />
                <div>Per Unit</div>
                <table className="BOM-table">
                    <tbody className="batchSheetWeightBasis">
                        <tr className="BOM-row batchSheetHeader">
                            <th className="BOM-cell">Ingredient</th>
                            <th className="BOM-cell">Kilograms</th>
                            <th className="BOM-cell">Pounds</th>
                            <th className="BOM-cell">Liters</th>
                            <th className="BOM-cell">Gallons</th>
                        </tr>
                        {ingredient_rows_unit}
                        <tr className="BOM-row BOM-row-bottom">
                            <td className="BOM-cell">Total</td>
                            <td className="BOM-cell">{recipe.f_total_weight_per_unit_kg}</td>
                            <td className="BOM-cell" >{recipe.f_total_weight_per_unit_lb}</td>
                            <td className="BOM-cell">{recipe.f_total_vol_per_unit_L}</td>
                            <td className="BOM-cell">{recipe.f_total_vol_per_unit_gal}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="BOMseparator">
                    <div >Per Case: </div>
                    <div>Units per Case: {units_per_case}</div>

                </div>
                <table className="BOM-table">
                    <tbody className=" batchSheetWeightBasis">
                        <tr className="BOM-row batchSheetHeader">
                            <th className="BOM-cell">Ingredient</th>
                            <th className="BOM-cell">Kilograms</th>
                            <th className="BOM-cell">Pounds</th>
                            <th className="BOM-cell">Liters</th>
                            <th className="BOM-cell">Gallons</th>
                        </tr>
                        {ingredient_rows_case}
                        <tr className="BOM-row BOM-row-bottom">
                            <td className="BOM-cell">Total</td>
                            <td className="BOM-cell">{recipe.f_total_weight_per_case_kg}</td>
                            <td className="BOM-cell">{recipe.f_total_weight_per_case_lb}</td>
                            <td className="BOM-cell">{recipe.f_total_vol_per_case_L}</td>
                            <td className="BOM-cell">{recipe.f_total_vol_per_case_gal}</td>
                        </tr>
                    </tbody>
                </table>

            </div >
        )
    }
}
export default withRouter(BillOfMaterials)